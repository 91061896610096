import React from 'react';
import { useTheme } from '@mui/material/styles';
import { useDispatch, useSelector } from 'react-redux';
import Box from '@mui/material/Box';
import Menu from '@mui/material/Menu';
import MenuList from '@mui/material/MenuList';
import MenuItem from '@mui/material/MenuItem';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import Button from '@mui/material/Button';
import ListItemButton from '@mui/material/ListItemButton';

import { themeProps } from '../../styles/theme';
import {
  getCurrentLocation,
  getCurrentLocationChildLocations,
  getLocationIdsByParentId,
  getLocationsById,
} from '../../state/selectors';
import { locationTypeDetail } from '../LocationConfig/LocationProperties';
import { LocationType, PropLocationItem } from '../../services/api';
import { setBleLocSwitchStatus, setCurrentLocation } from '../../state/actions';
import { persistState, StorageTypes } from '../../utils/persistentState';

interface MobileLocationSwitcherProps {
  anchorConfig: HTMLElement | null;
  hideSwitcher: () => void;
}

function MobileLocationSwitcher({
  anchorConfig,
  hideSwitcher,
}: MobileLocationSwitcherProps): JSX.Element {
  const dispatch = useDispatch();
  const theme = useTheme();
  const locationsByParent = useSelector(getLocationIdsByParentId);
  const childLocations = useSelector(getCurrentLocationChildLocations);
  const currentLocation = useSelector(getCurrentLocation);
  const locationsById = useSelector(getLocationsById);
  const currentLocationDetails = locationsById.get(currentLocation);
  const parentLocDetails = locationsById.get(currentLocationDetails?.location ?? '');

  const locSiblings = locationsByParent
    .get(parentLocDetails?.id ?? '')
    ?.map((sibling) => locationsById.get(sibling) ?? { id: sibling });

  const sortLocation = (locations: PropLocationItem[]) => {
    const sortedItem = Array.from(locations.values()).sort(
      (a: PropLocationItem, b: PropLocationItem) => {
        const nameA = a.shortName ?? '';
        const nameB = b.shortName ?? '';

        if (nameA > nameB) {
          return 1;
        }

        if (nameA < nameB) {
          return -1;
        }

        return 0;
      }
    );
    return sortedItem;
  };

  const getListItem = (location: PropLocationItem) => {
    const handleClick = () => {
      if (location.id !== currentLocation) dispatch(setBleLocSwitchStatus(false));
      dispatch(setCurrentLocation(location.id));
      persistState(location.id, StorageTypes.CurrentLocation);
      hideSwitcher();
    };

    const Icon = locationTypeDetail[location.type ?? LocationType.Building].icon;
    return (
      <MenuItem
        key={location.id}
        onClick={handleClick}
        sx={{
          background: theme.palette.primary.light,
          borderRadius: '8px',
          margin: '5px 0',
          padding: '5px 16px',
        }}
      >
        <Icon sx={{ color: themeProps.colors.warningRed }} />
        <ListItemButton sx={{ marginLeft: '10px' }}>{location.name ?? location.id}</ListItemButton>
      </MenuItem>
    );
  };

  return (
    <Menu
      open={Boolean(anchorConfig)}
      anchorEl={anchorConfig}
      style={{ textAlign: 'center' }}
      onClose={hideSwitcher}
    >
      <DialogTitle
        sx={{ textAlign: 'left', padding: '16px 24px 8px 24px', fontSize: themeProps.textSize.h5 }}
      >
        Select a Location
      </DialogTitle>
      <DialogContent>
        <MenuList>
          {parentLocDetails && getListItem(parentLocDetails)}

          {childLocations.length > 0 && (
            <Box>
              <hr style={{ border: `solid 1px ${theme.palette.text.primary}50` }} />
              {sortLocation(childLocations).map((location) => getListItem(location))}
            </Box>
          )}

          {locSiblings && (
            <Box>
              <hr style={{ border: `solid 1px ${theme.palette.text.primary}50` }} />
              {sortLocation(locSiblings).map((sibling) => getListItem(sibling))}
            </Box>
          )}
        </MenuList>

        <Button
          variant={themeProps.btnVariant.default}
          sx={{
            margin: '10px',
            width: '50%',
            background: themeProps.colors.warningRed,
            border: 'none',
          }}
          onClick={hideSwitcher}
        >
          Close
        </Button>
      </DialogContent>
    </Menu>
  );
}

export default MobileLocationSwitcher;
